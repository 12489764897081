@tailwind base;
@tailwind components;
@tailwind utilities;
.tbody tr:nth-child(2n-1) {
    background-color: #ededed;
}

.tbody tr:hover {
    background-color: #ededed;
}

.multi-range-slider .thumb::before,
.multi-range-slider .bar-left,
.multi-range-slider .bar-right {
    box-shadow: none !important;
}

@media print {
    .no-print {
        display: none;
    }
}

path title {
    color: white !important;
}